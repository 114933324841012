// All the custom components here
// 
// accordian
// 
.accordion {
  .card {
    margin-bottom: 1px;
  }
}

// 
// button
// 
.btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/12px auto no-repeat;
}

.btn-info,
.btn-danger,
.btn-success,
.btn-warning,
.btn-info:hover,
.btn-danger:hover,
.btn-success:hover,
.btn-warning:hover,
.btn-outline-info:hover,
.btn-outline-danger:hover,
.btn-outline-success:hover,
.btn-outline-warning:hover{
    color: $white;
}

//
// Badge
//

.badge {
    &.badge-pill {
      padding: 0.2em 0.6em;
    }
  }

//
// Card
//

.card {
  margin-bottom: 20px;
  .card-subtitle {
    margin-bottom: 10px;
    color: $text-muted;
  }
  .card-title {
    position: relative;
    margin-bottom: 10px;
    font-size: 20px;
  }
}

//
// Table Common
//

.table thead th,
.table th {
  font-weight: 500;
}
