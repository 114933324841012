/*******************
 General Topbar
*******************/

.topbar {
  position: relative;
  transition: $transitions;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
  background: $topbar;
  height: $topbar-height;
  .navbar-collapse {
    padding: 0px 10px 0 0;
  }
  .top-navbar {
    min-height: 0;
    padding: 0px; 
    .navbar-header {
      line-height: 45px;
      z-index: 100;
      .navbar-brand {
        display: flex;
        align-items: center;
        margin: 0px;
        padding: $topbar-navbrand-padding;
        .logo-icon {
          margin-right: 5px;
        }
      }
    }
    .navbar-nav > .nav-item > .nav-link {
      padding: $topbar-navlink-padding;
      font-size: $topbar-navlink-font-size;
      line-height:0;
      height: 18px;
    }
    .navbar-nav > .nav-item {
      transition: 0.2s ease-in;
      &:hover {
        background: $transparent-dark-bg;
      }
    }
  }
  .nav-toggler,
  .topbartoggler {
    color: $theme-light;
    padding: 0 15px;
  }
}

/*******************
Designed dropdown
******************/
.topbar {
  .dropdown-menu {
    padding-top: 0px;
    border: 0px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  }
}
/*******************
Topbar Responsive
******************/

@media (max-width: 991px) {
  .topbar .top-navbar {
    .navbar-collapse.show,
    .navbar-collapse.collapsing {
      display: block;
      width: 100%;
      border-top: 1px solid $border-color;
      position: fixed;
    }
    .navbar-nav {
      flex-direction: row;
      .dropdown {
        position: relative;
      }
    }
    .navbar-nav > .nav-item > .nav-link {
      padding: 0 10px;
    }
    .navbar-header {
      display: flex;
      align-items: center;
      width: 100% !important;
      justify-content: space-between;
    }
  }
}

@media (max-width: 767px) {
  .topbar .top-navbar {
    .navbar-nav {
      flex-direction: row;
      .dropdown {
        position: static;
      }
    }
    .dropdown-menu {
      position: absolute;
      width: 100%;
      left: 0 !important;
      transform: translate(0px, 64px) !important;
      .with-arrow {
        display: none;
      }
    }
  }
}


@media (max-width: 991px) {
  .topbar .navbar-collapse {
    position: relative;
    top: 64px;
  }
  .topbar .top-navbar .navbar-header {
    position: fixed;
  }
}