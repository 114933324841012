// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body,
[class*="ag-theme-"] {
  font-family: verdana, Geneva, sans-serif !important;
  font-size: 11px;
  // font-weight: 700;
}
:root {
  --font-size-11: 11px;
}
.modal {
  --bs-modal-bg: #ffffff;
}
button.close {
  background: #fff;
  border: none;
  font-size: 25px;
  padding: 0px 10px;
}
.modal-header {
  padding: 5px 15px;
}
.form-control {
  background-color: #ffffff;
  border: 1px solid #cbd6e2;
  border-radius: 2px;
  font-size: var(--font-size-11);
  padding: 5px 5px;
}
.form-title {
  font-size: var(--font-size-11);
  margin-bottom: 4px;
  margin-left: 2px;
  font-family: verdana, Geneva, sans-serif !important;
}
.required {
  color: red;
}
.form-select {
  background-color: #fff;
  font-size: var(--font-size-11);
}

.form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 12px;
}

.form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 12px;
}

.form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 12px;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 12px;
}

.error-icon {
  height: 10px;
  margin-bottom: 1px;
}
.error {
  color: #f2545b;
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 400;
}
.card {
  background: #fff;
}
label.error {
  margin-bottom: 0;
}
#main-wrapper .left-sidebar {
  background: #202d3d !important;
  // min-height: 740px;
  overflow: scroll;
  overflow-x: hidden;
}

.left-sidebar::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: #202d3d;
}

.left-sidebar::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.left-sidebar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #202d3d;
}

/*.left-sidebar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.left-sidebar::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.left-sidebar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #192f3d;
}*/

#main-wrapper .left-sidebar .sidebar-nav ul {
  background: #202d3d !important;
}
#main-wrapper .left-sidebar .sidebar-nav ul .sidebar-item .sidebar-link {
  color: #ffffff !important;
  opacity: 1;
}

#main-wrapper .left-sidebar .sidebar-nav ul.collapse.in {
  background: #273542 !important;
}

.sidebar-nav .has-arrow.profile-dd::after {
  top: 105px;
  opacity: 0.8;
}
.sidebar-nav .has-arrow::after {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #fff;
  margin-left: 10px;
  right: 15px;
  cursor: pointer;
}
.sidebar-nav ul .sidebar-item .collapse.in .sidebar-link {
  padding: 8px 5px;
}
.sidebar-nav ul .sidebar-item .collapse.in .sidebar-link.active {
  background: #3a3f45;
  width: 98%;
}
.sidebar-nav .has-arrow::after {
  -webkit-transform: rotate(135deg) translate(0, -50%);
  -ms-transform: rotate(135deg) translate(0, -50%);
  -o-transform: rotate(135deg) translate(0, -50%);
  transform: rotate(135deg) translate(0, -50%);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.sidebar-nav .has-arrow[aria-expanded="true"]::after,
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}
#main-wrapper
  .left-sidebar
  .sidebar-nav
  ul
  .sidebar-item.active
  > .sidebar-link
  i {
  color: #ffffff !important;
}
.dark-logo {
  width: 90%;
  //    height: 40px;
}
.theme-green .bs-datepicker-head {
  background-color: #ffffff;
  color: #000;
}
.bs-datepicker-head button.next,
.bs-datepicker-head button.previous {
  color: #000;
}
button.current.ng-star-inserted {
  color: #000;
}
.bs-datepicker-head button.current {
  color: #000;
}
.bs-datepicker-container {
  padding: 0;
}
h5.modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.page-wrapper {
  background: #ffffff;
}
.header-title {
  padding: 5px 0;
  margin-bottom: -5px;
}
.header-title .card-title {
  padding: 5px 0 0px 5px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.btn-primary {
  background-color: #2962ff;
}
.table {
  --bs-table-bg: #f6f9ff;
  --bs-table-striped-bg: #fff;
}
.table thead th {
  background: rgba(0, 0, 0, 0.02);
  white-space: nowrap;
  text-transform: uppercase;
}
.table > :not(caption) > * > * {
  padding: 8px 18px;
  white-space: nowrap;
}
.read-more {
  color: #2962ff;
}
td.table-comments {
  white-space: revert;
}
.btn-border,
.btn-border:hover {
  border: 1px solid #9d9a9a;
  padding: 1px 4px;
  margin-left: 5px;
}
.btn-edit {
  color: #009688;
}
.btn-delete {
  color: #f2545b;
}

.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-next.disabled:after {
  display: none !important;
}
.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-previous.disabled:before {
  display: none !important;
}
.custom-pagination .ngx-pagination li {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  color: #000;
  margin: 10px 2px;
}
.ngx-pagination .current {
  background: #ffffff !important;
  border-color: #2962ff !important;
  color: #2962ff !important;
}
input[type="date"] {
  padding-right: 14px;
  padding-left: 8px !important;
  z-index: 1;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../images/icon/calendar.svg?v2") no-repeat;
  width: 15px;
  height: 15px;
  border-width: thin;
  margin-right: -8px;
  margin-top: -3px;
}
// .form-select {
//   --bs-form-select-bg-img: url("../images/icon/arrow-bottom.svg");
// }

.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #192f3d;
}
.modal-header {
  border-bottom: 2px solid #f1f1f2;
}
.row {
  --bs-gutter-x: 20px;
}
.btn-secondary {
  background: #d4d5db;
  color: #292f4c;
  border-color: #d4d5db;
}
.page-wrapper > .container-fluid,
.page-wrapper > .container-sm,
.page-wrapper > .container-md,
.page-wrapper > .container-lg,
.page-wrapper > .container-xl,
.page-wrapper > .container-xxl {
  padding: 0px 0px 0px 0px;
  // min-height: calc(100vh - 180px);
  max-width: 100%;
  margin: 0 auto;
}
.btn-transaction {
  padding: 5px 5px;
  margin-right: 5px;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .navbar-collapse[data-navbarbg="skin1"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin1"] {
  background: none;
  border-bottom: none;
  box-shadow: none;
}
li.nav-item.dropdown {
  visibility: hidden;
}
.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: #292f4c;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link
  i {
  color: #ffffff;
}
.menu-image {
  margin-right: 5px;
  margin-left: 10px;
}
ul#sidebarnav {
  padding-top: 0px;
}
.table {
  border: 1px solid #e9e9e9;
}
.input-group-text {
  background: #ffffff;
  padding: 0px 8px;
}

span.ag-paging-page-size {
  display: none;
}
.action-buttons-group {
  display: flex;
  border: 1px solid #e3e3e3;
  border-radius: 50px;
  padding: 0 10px;
  height: 20px;
  .custom-invest-button {
    padding: 0;
    border: none;
    margin-right: 6px;
    line-height: 1;
    &:hover {
      background: #fff;
      border: none;
    }
  }
  .print_btn {
    margin-right: 7px;
  }
  .save_layout_dropdow {
    height: 20px;
  }
  .reload_dropdown {
    height: 20px;
    .custom-invest-button {
      margin-right: 5px;
    }
  }
}

.custom-invest-select {
  margin: 8px 8px;
  border-radius: 2px;
  color: #000;
  border: 2px solid #e3e3e3;
  height: 28px;
  padding: 0 0 0 5px;
  width: 110px;
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4px;
  height: 20px;
}
.btn-custom-link {
  padding: 0px 3px;
}
.btn-custom-link img {
  height: 12px;
}
.ag-paging-panel {
  justify-content: center !important;
  border-top: 0 !important;
}
.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
  border: 1px solid #e3e3e300 !important;
  border-right: 1px solid #8e8e8e !important;
  border-bottom: 1.1px solid #8e8e8e !important;
}
.ag-pinned-right-cols-container {
  border-left: 1px solid #8e8e8e !important;
}

.come-from-modal.left .modal-dialog,
.come-from-modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 65%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.come-from-modal.left .modal-content,
.come-from-modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
  border: none;
}

.come-from-modal.left .modal-body,
.come-from-modal.right .modal-body {
  padding: 15px 30px 80px;
}
.come-from-modal.right.fade .modal-dialog {
  right: 0;
  -webkit-transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
  -moz-transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
  -o-transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
  transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
}

.come-from-modal.right.fade.in .modal-dialog {
  right: 0;
}
.ag-pinned-right-header .ag-header-cell-resize::after {
  background-color: unset !important;
}
.ag-header-cell-text {
  font-weight: 700 !important;
  font-size: var(--font-size-11) !important;
}
.ag-ltr .ag-cell {
  font-size: var(--font-size-11) !important;
  font-weight: 400 !important;
  color: #000;
}
.ag-icon-filter::before {
  content: url("../images/icon/filter_alt.svg") !important;
}
.ag-ltr .ag-floating-filter-button {
  margin-left: -20px !important;
}
.custom-card-dashboard {
  background: #fff;
  border: 1px solid #eee;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
}
.bottom-fixed {
  /* position: fixed;
    bottom: 20px;
    margin-right: auto;
    margin-left: auto; */
}
.btn-custom-footer {
  --bs-btn-bg: #fff !important;
  border-radius: 100px;
  border: 1px solid #cbd6e2;
  font-size: var(--font-size-11);
  white-space: nowrap;
}
.btn-custom-footer:disabled {
  background: #c3c3c3;
  color: #6f6f6f;
}
span.tooltip-ng {
  margin-left: 5px;
  font-size: var(--font-size-11);
}
.input-group input.form-control {
  border-right: none;
}
.btn-calendar-icon {
  border: 1px solid #cbd6e2;
  border-left: none;
}
.btn-calendar-icon:hover,
.btn-calendar-icon:active {
  background-color: #ffffff;
  border-color: #cbd6e2;
  height: 28px;
}
.btn-calendar-icon img {
  margin-top: -10px;
  margin-right: -4px;
}
ngb-datepicker.dropdown-menu,
.ngb-dp-header,
.ngb-dp-weekdays {
  background-color: #ffffff !important;
}
.ag-root-wrapper {
  border-radius: 1px;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  height: 20px;
  padding: 3px 8px;
}
.input-group {
  width: 95%;
}
.ag-center-cols-container .ag-row-odd,
.ag-pinned-right-cols-container .ag-row-odd {
  background-color: #f9f8f9;
}
.ag-theme-quartz input[class^="ag-"][type="text"] {
  border-radius: 2px !important;
}
.ng-select.ng-select-single .ng-select-container {
  height: 20px !important;
  font-size: 0.875rem !important;
  border-radius: 2px !important;
  border: none !important;
  background: transparent !important;
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: 5px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: -2px !important;
  left: 0;
  padding-left: 3px !important;
  padding-right: 50px;
}

ng-select.ng-select-custom .ng-select-container {
  width: 70%;
}

.ng-select .ng-select-container {
  min-height: 20px !important;
  border-radius: 0px !important;
  height: 20px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.user-menu-sidebar {
  position: absolute;
  bottom: auto;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
}
.user-menu-sidebar i.bi.bi-question-circle {
  font-size: 20px;
  color: #fff;
  margin-top: 5px;
}
.user-menu-sidebar button,
.user-menu-sidebar button:hover {
  background: #394f6c;
  color: #fff;
  border-color: #394f6c;
}
.user-menu-sidebar .dropdown-menu {
  --bs-dropdown-bg: #394f6c !important;
}
ul.navbar-nav.float-start.me-auto {
  position: absolute;
  left: auto;
  margin-left: -40px;
  z-index: 100;
}

#main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
  ul.navbar-nav.float-start.me-auto {
  margin-left: -35px;
}

.topbar .top-navbar .navbar-nav > .nav-item:hover {
  background: rgb(0 0 0 / 0%);
}
.container-fluid {
  position: relative;
  // top: -88px;
}
.custom-invest-filter {
  z-index: 9999;
}
.modal.fade.show {
  z-index: 99999;
}
#main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
  .container-fluid {
  z-index: unset;
}
.ag-header-cell-resize::after {
  width: 1px !important;
}
#main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
  .btn-leftside
  span,
#main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
  .dropup
  .btn-leftside::after {
  display: none;
}
#main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
  .user-menu-sidebar {
  display: block;
  text-align: center;
}
.ag-theme-quartz,
.ag-theme-quartz-dark,
.ag-theme-quartz-auto-dark {
  --ag-wrapper-border-radius: 2px !important;
}
.ag-header-row.ag-header-row-column {
  border-bottom: 1.5px solid #ddd !important;
  top: 1px !important;
  width: 100% !important;
}
li.sidebar-item.ng-star-inserted.active {
  border-left: 3px solid #478cf4;
}
.ag-header-row.ag-header-row-column,
.ag-header-cell.ag-column-first.ag-header-cell-sortable.ag-focus-managed,
.ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
  height: 30px !important;
}

.ag-header-row.ag-header-row-column-filter {
  top: 30px !important;
}

.ag-header.ag-focus-managed.ag-pivot-off.ag-header-allow-overflow {
  height: 60px !important;
  min-height: 58px !important;
}
.ag-header-row.ag-header-row-column-filter {
  height: 28px !important;
}
.ag-header-cell.ag-floating-filter.ag-focus-managed {
  margin-left: -1px;
}
.ag-cell {
  line-height: 18px !important;
}
.ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
  --ag-internal-calculated-line-height: 20px !important;
}
.come-from-modal h5.modal-title {
  padding-left: 15px;
}
.ag-cell-value.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-first-right-pinned {
  padding: 0 0 !important;
}
.ag-theme-quartz {
  --ag-grid-size: 5px !important;
}
.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding: 0px 0 0px 8px !important;
}
.ag-right-aligned-cell {
  padding-right: 8px !important;
}
.ag-header-cell,
.ag-header-group-cell {
  padding: 0px 15px !important;
}
.ag-theme-quartz,
.ag-theme-quartz-dark,
.ag-theme-quartz-auto-dark {
  --ag-header-height: calc(
    var(--ag-font-size) + var(--ag-grid-size) * 1.25
  ) !important;
  --ag-icon-size: 12px !important;
}
.ag-left {
  text-align: left !important;
}
/*.ag-theme-quartz .ag-body-horizontal-scroll-viewport { table scroll hide
    overflow-y: hidden !important;
}*/

span.ag-header-icon.ag-header-cell-menu-button {
  display: none;
}
#main-wrapper[data-layout="vertical"][data-sidebartype="full"] .page-wrapper {
  // height: calc(100vh - 64px);
}
#main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
  .left-sidebar:hover
  .user-menu-sidebar {
  display: flex;
  justify-content: space-evenly;
}
#main-wrapper[data-layout=vertical] .topbar .top-navbar .navbar-header[data-logobg=skin6] .navbar-brand .logo-text-mini .dark-logo {
  display: none;
}
// .left-sidebar:hover .btn-leftside span {
//   display: contents !important;
// }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 28%;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover {
    width: 167px;

  }
#main-wrapper .left-sidebar:hover .user-menu-sidebar span{
    display: block;
  }
  #main-wrapper .left-sidebar:hover button.dropdown-toggle:after{
     display: block !important;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    ul
    .sidebar-item:hover {
    width: 167px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    .hide-menu {
    display: block;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item.active
    > .sidebar-link {
    width: 80%;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item {
    width: 167px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar {
    width: 65px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebar-position="absolute"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-left: 0;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .page-wrapper {
    margin-left: 65px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    .hide-menu,
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    .has-arrow:after {
    display: none;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar
    .sidebar-nav
    ul
    .sidebar-item.active
    > .sidebar-link {
    width: 65%;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-header
    .logo-text {
    display: block;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    ul
    .sidebar-item {
    width: 65px;
  }
  #main-wrapper[data-layout="vertical"]
    .topbar
    .top-navbar
    .navbar-header[data-logobg="skin6"]
    .navbar-brand
    .logo-text-mini
    .dark-logo {
    display: none;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-header[data-logobg="skin6"]
    .navbar-brand
    .dark-logo {
    display: none;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-header[data-logobg="skin6"]
    .navbar-brand
    .logo-text-mini
    .dark-logo {
    display: inline;
  }
}

input[type="date"] {
  position: relative;
  color: black;
}

.datepicker-custom:nth-child(1):before {
  position: absolute;
  top: 8px;
  left: 3px;
  content: attr(data-date);
  display: inline-block;
  color: black;
}
.datepicker-custom:nth-child(2):before {
  position: absolute;
  top: 8px;
  left: 3px;
  content: attr(data-date);
  display: inline-block;
  color: black;
}
input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
inpinput[type="date"]ut::-webkit-clear-button {
  display: block;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 3px;
  right: 14px;
  color: black;
  opacity: 1;
}
.ag-input-field-input {
  font-size: var(--font-size-11) !important;
}
.ag-header-row.ag-header-row-column {
  z-index: 98;
}
.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 99999;
}
// .swal2-container .swal2-popup {
//     width: 40% !important;
// }
.ag-theme-quartz .ag-picker-field-wrapper,
.ag-theme-quartz-dark .ag-picker-field-wrapper,
.ag-theme-quartz-auto-dark .ag-picker-field-wrapper {
  min-height: calc(var(--ag-grid-size) * 2.5) !important;
}
.btn-save-sort {
  width: 100px;
}
.btn-save-resetsort {
  width: 100px;
}
.ag-center-aligned-cell {
  text-align: center;
}
.ag-simple-filter-body-wrapper {
  padding: 5px 5px !important;
}

.ag-simple-filter-body-wrapper > * {
  margin-bottom: 0px !important;
}
.ag-filter-select {
  margin-bottom: 5px !important;
}
.ag-picker-field-display {
  font-size: var(--font-size-11) !important;
}
.ag-select-list-item {
  font-size: var(--font-size-11) !important;
}
.ag-theme-quartz .ag-tabs-header,
.ag-theme-quartz-dark .ag-tabs-header,
.ag-theme-quartz-auto-dark .ag-tabs-header {
  display: none !important;
}
.ag-theme-quartz .ag-menu.ag-tabs,
.ag-theme-quartz-dark .ag-menu.ag-tabs,
.ag-theme-quartz-auto-dark .ag-menu.ag-tabs {
  min-width: 126px !important;
  margin-top: 4px;
}
.padding-model {
  padding: 0px 15px 15px 15px;
}
.ag-paging-panel > * {
  font-size: var(--font-size-11);
}
.ag-multi-filter
  form.ag-filter-wrapper.ag-focus-managed
  .ag-filter-body-wrapper.ag-simple-filter-body-wrapper {
  display: none;
}
.ag-multi-filter .ag-mini-filter {
  margin: 5px 5px;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder,
span.ng-option-label,
.ng-select div,
.ng-select input,
.ng-select span {
  font-size: var(--font-size-11);
}
.text-right {
  text-align: right;
}
// .ft-width {
//     width: 70%;
// }
// .sd-width {
//     width: 85%;
// }

// .form-editing .row {
//     padding: 0px 0 0 15px;
// }
// .form-editing .col-md-3 {
//     margin-left: -15px;
// }
// .col-md-12 {
//     margin-left: -15px;
// }
// .custom-margin-1 {
//     margin-left: -20px !important;
// }

.ag-popup {
  right: auto !important;
  left: 0 !important;
}

.ag-popup-panel {
  right: auto !important;
  left: 0 !important;
}
bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 9999;
}
.from-daterange {
  // width: 170px;
  height: 28px;
  border-radius: 100px;
  margin-top: 10px;
  font-size: var(--font-size-11);
}
.custom-tab-button {
  background: #fff;
  margin-right: 0.5rem;
  margin: 8px 8px;
  border: 1px solid #e9e9e9;
  padding: 0 10px;
  font-size: var(--font-size-11);
  height: 28px;
  border-radius: 100px;
}
.custom-tab-button.active {
  background: #202d3d;
  color: #fff;
}
.date-range {
  margin: 0 8px;
}
.custom-filter-select {
  margin: 6px 0;
}

.custom-form-select {
  margin: 0px 0;
  color: #54667a !important;
  border: 1px solid #cbd6e2 !important;
  width: 100% !important;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
  z-index: 99999  !important;
}

#header-row.common-header {
  padding-right: 10px;
  z-index: 99999;
  position: relative;
  .custom-invest-select {
    margin-left: 0;
    margin-right: 0.5rem;
    height: 20px;
    border-width: 1px;
    margin-top: 12px;
    margin-bottom: 0;
    color: #54667a;
    padding-right: 5px !important;
  }
  .from-daterange {
    height: 20px;
    border-color: #e3e3e3;
  }
  .custom-filter-select {
    margin: 6px 0;
    height: 20px;
    padding-top: revert-layer;
    padding-bottom: 0;
    border-radius: 0;
    padding-left: 3px;
    height: 20px;
    color: #54667a !important;
    border: 1px solid #e9e9e9 !important;
    padding-right: 0;
    font-family: verdana, Geneva, sans-serif !important;
  }

  .custom-form-select {
    margin: 6px 0;
    height: 20px;
    padding-top: revert-layer;
    padding-bottom: 0;
    border-radius: 0;
    padding-left: 3px;
    height: 20px;
    color: #54667a;
    border: 1px solid #e9e9e9;
    padding-right: 0;
    font-family: verdana, Geneva, sans-serif !important;
  }

  .custom-tab-button {
    margin: 0;
    height: 20px;
    margin-right: 0.5rem;
  }
  .daterange_sec {
    border-radius: 50px;
    height: 20px;
    padding: 0;
  }
  .from-daterange {
    border-radius: 50px;
    height: 20px;
    padding: 0 10px;
    min-width: 178px;
  }
  // .custom-invest-button{
  //     margin: 0;
  //     height:20px;
  // }
  .select_account_filter .custom-filter-select {
    width: 110px;
  }
  .select_clutch_filter .custom-filter-select {
    width: 80px;
  }
  .selectbox-per-page {
    width: 116px;
    margin-top: 0;
  }
  .custom-invest-filter {
    align-items: center;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    list-style: none;
    z-index: 1;
    width: 65px;
  }
  .dropdown-content > li {
    cursor: pointer;
    background-color: #fff;
    padding: 1px;
    font-weight: normal;
  }
  .show {
    display: block;
  }

  .hide {
    display: none;
  }
  .single_select_filter {
    margin: 0;
    label {
      min-width: 43.08px;
    }
    .custom-filter-select {
      min-width: 178px;
    }
  }
  .select_currency {
    width: 56px;
  }
}
.reload_dropdown .dropdown-content {
  border: 1px solid #e9e9e9;
  padding: 5px;
  background: #fff;
  border-radius: 4px;
  top: 22px;
  right: 0;
}
.swal2-container {
  .swal2-modal.swal2-icon-error {
    max-height: 96vh;
    padding-bottom: 8px;
    width: 100%;
    max-width: 600px;
  }
  .swal2-title {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    padding-top: 0;
  }
  .swal2-icon.swal2-error.swal2-icon-show {
    width: 40px;
    margin-top: 20px;
    height: 40px;
    border-width: 2px !important;
    .swal2-x-mark {
      .swal2-x-mark-line-left,
      .swal2-x-mark-line-right {
        top: 19px !important;
        left: 8px !important;
        height: 2px !important;
        width: 24px !important;
      }
    }
  }
  .swal2-popup.swal2-icon-success {
    padding-bottom: 10px;
    .swal2-icon.swal2-success {
      margin-top: 20px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .swal2-success-ring {
      width: 70px !important;
      height: 70px !important;
    }
    .swal2-success-line-tip {
      top: 42px !important;
      left: 9px !important;
    }
    .swal2-success-line-long {
      left: 22px;
      top: 35px !important;
    }
  }
  .swal2-html-container {
    margin-left: 12px;
    margin-right: 12px;
    .poprow {
      max-height: 70vh;
      overflow-y: scroll;
      font-size: 11px;
      text-align: left;
      color: #000;
      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
      }
      hr {
        margin: 7px 0;
        border-top-color: #c4c4c4;
      }
      br {
        display: none;
      }
    }
  }
  .swal2-actions {
    margin-top: 10px;
    button.swal2-styled {
      padding: 5px 12px !important;
      font-size: 14px;
      min-width: 62px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.save_layout_dropdown .dropdown-content {
  border: 1px solid #e9e9e9;
  padding: 5px;
  background: #fff;
  border-radius: 4px;
  top: 22px;
  right: 0;
  width: 60px;
}

.modal-open .modal {
  display: flex !important;
}
input,
button,
select,
optgroup,
textarea {
  font-family: verdana, Geneva, sans-serif !important;
}

textarea {
  min-height: 70px !important;
}

input,
select,
optgroup {
  font-family: verdana, Geneva, sans-serif !important;
  height: 20px;
}

// .reload_dropdown {
//     margin-right: 8px;
// }
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: none;
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

ng-select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: 1px solid #cbd6e2;
  border-radius: 0;
  outline: 0;
}

.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none !important;
}

::ng-deep body > ng-dropdown-panel,
::ng-deep .scrollable-box ng-dropdown-panel {
  margin-top: -20px;
}

.cell-border {
  border: 2px solid #00a2e8 !important;
  height: 20px !important;
  margin-top: -1px;
}

.check-bank-grid {
  height: calc(100vh - 54px);
  width: 426px;
  @media screen and (min-width: 1879px) {
    height: calc(100vh - 48px);
  }
  .ag-center-cols-container .ag-row-odd,
  .ag-pinned-right-cols-container .ag-row-odd {
    background-color: #e1e1e1;
  }
  .ag-header-row-column {
    .ag-header-cell-text {
      display: inline-block;
      color: #3e5569;
      cursor: pointer;
    }
  }
  .ag-header-cell,
  .ag-header-group-cell {
    padding: 0px 5px !important;
  }
}

.ag-input-wrapper,
.ag-picker-field-wrapper {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  line-height: 5;
  position: relative;
  margin-top: 1px;
  margin-bottom: 1px;
}

.bank-master-grid {
  height: calc(100vh - 41px);
  width: 478px;
  padding-left: 5px;
  .ag-header-cell.ag-column-first {
    height: auto !important;
  }
  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding: 0px 5px 0px 5px !important;
  }
  .ag-cell-label-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    height: 100%;
    width: 100%;
    padding: 5px 0px 8px;
  }

  .ag-header-row.ag-header-row-column {
    border-bottom: none !important;
    top: 1px !important;
    width: 100% !important;
  }

  .ag-header-row.ag-header-row-column,
  .ag-header-cell.ag-column-first.ag-header-cell-sortable.ag-focus-managed,
  .ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
    height: 27px !important;
  }

  .ag-center-cols-container .ag-row-odd,
  .ag-pinned-right-cols-container .ag-row-odd {
    background-color: #e1e1e1;
  }
  .ag-header-row-column {
    .ag-header-cell-text {
      display: inline-block;
      color: #3e5569;
      cursor: pointer;
      margin-left: 3px;
    }
  }
  .ag-header-cell,
  .ag-header-group-cell {
    padding: 0px 2px !important;
  }

  // .ag-header-row {
  //     position: static !important;
  // }

  .ag-floating-filter-full-body {
    display: inline-table !important;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    align-items: center;
    overflow: hidden;
  }

  .ag-input-wrapper,
  .ag-picker-field-wrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    line-height: 5;
    position: relative;
    margin-top: 2px;
    margin-bottom: 1px;
  }

  input {
    min-height: 20px !important;
  }
  .ag-row {
    border-bottom: none !important;
  }
  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    border: 1px solid rgba(227, 227, 227, 0) !important;
    border-right: 1px solid #c4c4c4 !important;
    border-bottom: 1.1px solid #c4c4c4 !important;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    width: 10px;
    background-size: 16px;
  }

  .ag-theme-quartz input[class^="ag-"][type="date"],
  .ag-theme-quartz .ag-ltr input[class^="ag-"][type="text"],
  .ag-theme-quartz .ag-ltr input[class^="ag-"][type="number"] {
    //  min-height: calc(var(--ag-grid-size) * 2) !important;
    min-height: 20px !important;
    margin-top: 1px;
    border-radius: 0px !important;
  }
}

.iframe-container {
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
  .ag-body-vertical-scroll {
    width: 10px !important;
    max-width: 10px !important;
    min-width: 10px !important;
    .ag-body-vertical-scroll-viewport {
      width: 10px !important;
      max-width: 10px !important;
      min-width: 10px !important;
      .ag-body-vertical-scroll-container {
        width: 10px !important;
        max-width: 10px !important;
        min-width: 10px !important;
      }
    }
  }

  .main-banks-grid {
    display: flex;
    height: calc(100vh - 42px);
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 5px;
    .ag-paging-panel > * {
      margin: 0 12px;
    }
    .posted-cell {
      padding-left: 5px !important;
    }
    .account-cell {
      padding-left: 3px !important;
    }
    .check-bank-grid {
      margin-right: 5px;
      .ag-floating-filter-button-button {
        &:hover {
          box-shadow: none !important;
          background-color: #fff !important;
        }
      }
      .ag-floating-filter-button {
        z-index: 1;
        .ag-icon-filter {
          &:before {
            content: var(--ag-icon-font-code-filter, "\f115") !important;
          }
        }
      }
      .ag-floating-filter-input {
        input[type="date"].datepicker-custom::-webkit-calendar-picker-indicator {
          padding-right: 14px;
          margin-top: -2px !important;
        }
      }
      .ag-floating-filter {
        .ag-floating-filter-button {
          margin-left: -17px !important;
          margin-top: -2px;
          z-index: 1;
        }
        .ag-text-field-input {
          margin-top: -1px !important;
          background-color: #fff;
        }
      }
      .ag-popup {
        .ag-picker-field-wrapper {
          height: 25px;
        }
        .ag-picker-field-wrapper.ag-picker-has-focus,
        .ag-picker-field-wrapper:focus-within {
          outline: none;
          box-shadow: none !important;
          border-color: #dddddd !important;
        }
        .ag-tabs.ag-menu.ag-ltr {
          // top: 50px !important;
          // left: 2px !important;
          margin-left: -12px !important;
          top: 51px !important;
          border-radius: 2px;
        }
      }
      .ag-match-dropdown select {
        border: none !important;
      }
    }

    .container-grid {
      margin-right: 5px;
      .ag-floating-filter-button-button {
        &:hover {
          box-shadow: none !important;
          background-color: #fff !important;
        }
      }
      .ag-floating-filter-button {
        z-index: 1;
        .ag-icon-filter {
          &:before {
            content: var(--ag-icon-font-code-filter, "\f115") !important;
          }
        }
      }
      .ag-floating-filter-input {
        input[type="date"].datepicker-custom::-webkit-calendar-picker-indicator {
          padding-right: 14px;
          margin-top: -2px !important;
        }
      }

      .ag-floating-filter {
        .ag-floating-filter-button {
          margin-left: -17px !important;
          margin-top: -2px;
          z-index: 1;
        }
        .ag-text-field-input {
          margin-top: -1px !important;
        }
      }
      .ag-popup {
        .ag-picker-field-wrapper {
          height: 25px;
        }
        .ag-picker-field-wrapper.ag-picker-has-focus,
        .ag-picker-field-wrapper:focus-within {
          outline: none;
          box-shadow: none !important;
          border-color: #dddddd !important;
        }
        .ag-tabs.ag-menu.ag-ltr {
          margin-left: 0 !important;
          top: 50px !important;
          left: 2px !important;
          border-radius: 2px;
        }
      }
    }
    .ag-header-label-icon.ag-filter-icon {
      display: none;
    }

    .bank-grid-first {
      select {
        margin-top: -1px !important;
      }
      input[type="date"].datepicker-custom {
        margin-top: 0;
      }
    }
    select {
      color: #54667a;
      margin-top: -1px !important;
    }
    input[type="date"].datepicker-custom {
      min-height: 20px !important;
      height: 20px !important;
      border-radius: 0;
      margin-top: -1px;
      margin-right: 3px;
      background: #fff;
    }
    ::-webkit-calendar-picker-indicator {
      width: 10px;
      min-width: 10px;
      height: 10px;
      border-width: thin;
      background-size: 16px;
    }
  }
}

@media screen and (max-width: 1878px) {
  #header-row.common-header {
    .save-icon::before {
      width: 16px;
      vertical-align: middle;
      color: #000;
    }
    .from-daterange {
      min-width: 154px;
    }
    .selectbox-per-page {
      width: 90px;
    }
    .header-title {
      .card-title {
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .select_bank_filter {
      .custom-filter-select {
        width: 70px;
      }
    }
    .select_account_filter {
      .custom-filter-select {
        width: 70px;
      }
    }
  }
}

.container-grid {
  height: calc(100vh - 70px);
  width: 100%;
  padding-left: 5px;
@media screen and (max-width:991px){
  height: calc(100vh - 116px);
}
  .ag-floating-filter {
  }
  .ag-center-cols-container .ag-row-odd,
  .ag-pinned-right-cols-container .ag-row-odd {
    background-color: #e1e1e1;
  }
  .ag-header-row-column {
    .ag-header-cell-text {
      display: inline-block;
      color: #3e5569;
      cursor: pointer;
    }
  }
  .ag-header-cell,
  .ag-header-group-cell {
    padding: 0px 5px !important;
  }
  .ag-header-cell.ag-column-first {
    height: auto !important;
  }

  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding: 0px 5px 0px 5px !important;
  }
  .ag-cell-label-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    height: 100%;
    width: 100%;
    padding: 5px 0px 8px;
  }

  .ag-header-row.ag-header-row-column {
    border-bottom: none !important;
    top: 1px !important;
    width: 100% !important;
  }

  .ag-header-row.ag-header-row-column,
  .ag-header-cell.ag-column-first.ag-header-cell-sortable.ag-focus-managed,
  .ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
    height: 27px !important;
  }

  .ag-center-cols-container .ag-row-odd,
  .ag-pinned-right-cols-container .ag-row-odd {
    background-color: #e1e1e1;
  }
  .ag-header-row-column {
    .ag-header-cell-text {
      display: inline-block;
      color: #3e5569;
      cursor: pointer;
      margin-left: 3px;
    }
  }
  /*.ag-header-cell,
  .ag-header-group-cell {
    padding: 0px 2px !important;
  }*/

  // .ag-header-row {
  //     position: static !important;
  // }

  .ag-floating-filter-full-body {
    display: inline-table !important;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    align-items: center;
    overflow: hidden;
  }

  .ag-input-wrapper,
  .ag-picker-field-wrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    line-height: 5;
    position: relative;
    margin-top: 2px;
    margin-bottom: 3px;
  }

  input {
    min-height: 20px !important;
  }
  .ag-row {
    border-bottom: none !important;
  }
  .ag-cell,
  .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    border: 1px solid rgba(227, 227, 227, 0) !important;
    border-right: 1px solid #c4c4c4 !important;
    border-bottom: 1.1px solid #c4c4c4 !important;
  }

  // input[type="date"]::-webkit-calendar-picker-indicator {
  //     color: rgba(0, 0, 0, 0);
  //     opacity: 1;
  //     display: block;
  //     background: transparent !important;
  //     width: 100%;
  //     height: 20px;
  //     border-width: thin;
  //     margin-right: -8px;
  //     margin-top: -3px;
  // }
  input[type="date"]::-webkit-calendar-picker-indicator {
    width: 10px;
    background-size: 16px;
  }
}

.number-right-align {
  text-align: center;
  padding-right: 8px !important;
}

.fx-rate-grid {
  height: calc(100vh - 48px);
  width: 694px;
  margin-right: 5px;
  .ag-floating-filter-button-button {
    &:hover {
      box-shadow: none !important;
      background-color: #fff !important;
    }
  }
  .ag-floating-filter-button {
    z-index: 1;
    .ag-icon-filter {
      &:before {
        content: var(--ag-icon-font-code-filter, "\f115") !important;
      }
    }
  }
  .ag-floating-filter-input {
    input[type="date"].datepicker-custom::-webkit-calendar-picker-indicator {
      padding-right: 14px;
      margin-top: -2px !important;
    }
  }

  .ag-popup {
    .ag-picker-field-wrapper.ag-picker-has-focus,
    .ag-picker-field-wrapper:focus-within {
      outline: none;
      box-shadow: none !important;
      border-color: #dddddd !important;
    }
    .ag-tabs.ag-menu.ag-ltr {
      margin-left: 0 !important;
      top: 50px !important;
      left: 2px !important;
      border-radius: 2px;
    }
    .ag-picker-field-wrapper {
      height: 25px;
    }
  }
  .ag-center-cols-container .ag-row-odd,
  .ag-pinned-right-cols-container .ag-row-odd {
    background-color: #e1e1e1;
  }
  .ag-header-row-column {
    .ag-header-cell-text {
      display: inline-block;
      color: #3e5569;
      cursor: pointer;
    }
    .ag-header-cell-label {
      justify-content: center;
    }
    .ag-column-first {
      .ag-header-cell-label {
        justify-content: start;
      }
    }
  }
  .ag-header-cell,
  .ag-header-group-cell {
    padding: 0px 2px !important;
  }
  .ag-header-row {
    .ag-header-cell.ag-column-first {
      padding-left: 4px !important;
      padding-right: 7px !important;
    }
    .ag-input-field-input.ag-text-field-input {
      margin-top: 0 !important;
      padding-left: 2px !important;
    }
  }
}
input[class^="ag-"]:not([type]):focus,
input[class^="ag-"][type="text"]:focus,
input[class^="ag-"][type="number"]:focus,
input[class^="ag-"][type="tel"]:focus,
input[class^="ag-"][type="date"]:focus,
input[class^="ag-"][type="datetime-local"]:focus,
textarea[class^="ag-"]:focus {
  outline: none !important;
  box-shadow: unset !important;
  border-color: #dddddd !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
// bs-daterangepicker-container.bottom{
//     left: 95px !important;
//   }

/* Multi Select Dropdown */
.mutiselect_account {
  .ng-select {
    width: 130px;
    border: none;
    .ng-arrow-wrapper {
      width: auto;
      .ng-arrow {
        top: 0 !important;
        border: none;
        height: 10px;
        width: 10px;
        &::after {
          position: absolute;
          content: "";
          width: 7px;
          height: 7px;
          border-width: 2px 0 0 2px;
          border-style: solid;
          border-color: #54667a;
          margin-left: 10px;
          cursor: pointer;
          right: 0;
          top: 50%;
          transform: rotate(223deg) translate(0, -50%);
          transform-origin: top;
          transition: all 0.3s ease-out;
        }
      }
    }
    .ng-select-container {
      border: 1px solid #e9e9e9 !important;
      // display: block;
      // overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .ng-placeholder {
        top: 1px !important;
        padding-bottom: 0 !important;
        padding-left: 3px !important;
        color: #54667a !important;
      }
      .ng-value-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px !important;
        flex-wrap: nowrap !important;
        padding-top: 2px !important;
        .ng-value {
          background-color: transparent !important;
          margin: 0 !important;
        }
        .ng-value-label {
          padding: 0px 3px !important;
          color: #54667a !important;
        }
      }
    }
    .ng-dropdown-panel {
      z-index: 99999;
      .ng-dropdown-panel-items {
        .ng-option {
          color: #54667a;
          padding: 0 2px;
          padding-left: 2px !important;
          text-align: center;
        }
        .ng-option-selected {
          background-color: #e1e1e1 !important;
          .ng-option-label {
            font-weight: 500 !important;
            color: #000;
          }
        }
        .ng-optgroup {
          -webkit-user-select: none;
          user-select: none;
          padding: 2px 2px;
          color: #54667a;
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
  .ng-select-opened {
    .ng-arrow-wrapper {
      .ng-arrow {
        top: 3px !important;
        &::after {
          transform: rotate(45deg) translate(0, -50%);
          transform-origin: top;
          transition: all 0.3s ease-out;
        }
      }
    }
  }
}
.bank_mutiselect {
  .ng-select {
    width: 95px;
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          padding-left: 7px !important;
          text-align: left;
        }
      }
    }
  }
}

.check-bank-grid .ag-select-list .ag-select-list-item,
.fx-rate-grid .ag-select-list .ag-select-list-item {
  height: 20px;
}
.save_layout_dropdown {
  margin-top: 1px;
}

.container-grid {
  .ag-header-label-icon.ag-filter-icon {
    display: none;
  }
  .ag-floating-filter-button {
    z-index: 1;
    margin-left: -12px !important;
    right: 2px;
    top: -1px;
    position: relative;
    .ag-floating-filter-button-button {
      &:hover {
        box-shadow: none !important;
        background-color: #fff !important;
      }
    }
    .ag-icon-filter {
      &:before {
        content: var(--ag-icon-font-code-filter, "\f115") !important;
      }
    }
  }
  .ag-floating-filter {
    input[type="date"].datepicker-custom::-webkit-calendar-picker-indicator {
      padding-right: 14px;
      margin-top: -2px !important;
    }
  }
  .ag-popup {
    .ag-tabs.ag-menu.ag-ltr {
      margin-left: 0 !important;
      top: 50px !important;
      border-radius: 2px;
    }
    .ag-picker-field-wrapper.ag-picker-has-focus,
    .ag-picker-field-wrapper:focus-within {
      outline: none;
      box-shadow: none !important;
      border-color: #dddddd !important;
    }
    .ag-picker-field-wrapper {
      height: 25px;
    }
  }
  .ag-body-viewport{
    @media screen and (max-width:767px){
      padding-bottom: 10px;
    }
    .ag-cell-value{
      &:last-child{
        @media screen and (max-width:767px){
          padding-right: 12px !important;
        }
      }
    }
  }
}

.common-grids {
  .cell-border {
    border: 2px solid #00a2e8 !important;
  }
  .ag-floating-bottom-viewport {
    .ag-cell-value {
      font-weight: 600 !important;
      &:last-child{
        @media screen and (max-width:767px){
          padding-right: 12px !important;
        }
      }
    }
    .ag-cell {
      border-right: 0 !important;
    }
    .ag-row-even.ag-row {
      .ag-cell.cell-border {
        &:last-child {
          border-right: 2px solid #00a2e8 !important;
        }
      }
    }
  }

  .datepicker-custom {
    color: #54667a !important;
    padding-right: 32px !important;
    padding-left: 5px !important;
    border-radius: 2px !important;
    margin-top: -3px;
  }
}
.fx-rate-grid,
.check-bank-grid {
  .ag-row {
    border-right: 1px solid #8e8e8e;
  }
}

.main-banks-grid .ag-header-container .ag-pinned-right-header {
  border-bottom: none !important;
}

.ag-floating-filter-input .ag-input-field-input[type="date"] {
  width: 1px;
  color: white;
}

.bankaudit-grid {
  width: 100%;
  height: auto;
  .bankaudit-inner {
    display: flex;
    height: calc(100vh - 42px);
    overflow-x: auto;
    overflow-y: hidden;
  }
  .fx-rate-grid {
    width: 1232px;
    .ag-floating-bottom .ag-row {
      border-right: 0;
    }
    .ag-header-row-column {
      .ag-header-cell-label {
        justify-content: flex-start;
      }
    }
    .ag-popup {
      .ag-tabs.ag-menu.ag-ltr {
        left: auto !important;
        right: 15px;
        top: 51px !important;
        border-radius: 2px;
      }
    }
  }
  .bankaudit-amount-grid {
    width: 215px;
    height: calc(100vh - 48px);
    margin-right: 5px;
    .ag-button.ag-paging-button {
      margin-left: 2px;
      margin-right: 2px;
    }
    .ag-paging-panel > * {
      margin-left: 0;
    }
    .ag-paging-page-summary-panel {
      margin-right: 0;
    }
  }
}

.ag-default-datepicker-font {
  font-family: verdana, Geneva, sans-serif !important;
  font-size: 11px;
}

.ag-checkbox-input-wrapper {
  flex: none !important;
}

.ag-checkbox-input-wrapper.ag-checked::after {
  content: var(--ag-icon-font-code-checkbox-checked, "\f106");
  color: var(--ag-checkbox-checked-color) !important;
  position: absolute !important;
  top: unset !important;
  left: unset !important;
  pointer-events: none !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
button.swal2-confirm.swal2-styled {
  background: #202d3d;
  color: #fff;
}
.disabled {
  background-color: #fff;
  color: #54667a;
}
.search_filter input {
  max-width: 90px;
}
.ledger-filter input {
  max-width: 90px;
  color: #54667a;
  &::placeholder {
    color: #54667a;
  }
}
.ag-paging-panel {
  .custom-page-btn {
    line-height: 1.7 !important;
  }
}

.ag-body-horizontal-scroll-viewport,
.ag-body-horizontal-scroll {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important;
}

.tooltip1 {
  display: inline-block;
  font-size: 11px;
  font-family: verdana, Geneva, sans-serif !important;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
  white-space: nowrap;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  width: auto;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 0px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: -50%;
  left: auto;
  margin-left: -60px;
  transition: opacity 0.3s;
  border: 1px solid black;
  font-size: 11px;
  font-family: verdana, Geneva, sans-serif !important;
  font-weight: 100 !important;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

[ngbDatepickerDayView] {
  text-align: center;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 0.25rem;
  background: transparent;
  font-size: 12px;
}
ngb-datepicker-navigation-select > .form-select {
  flex: 1 1 auto;
  padding: 0 0.5rem;
  font-size: 11px !important;
  /* height: 1.85rem; */
  margin: 5px;
}

div:where(.swal2-container) .swal2-html-container {
  font-size: 12px !important;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 12px !important;
}

.swal2-container .swal2-actions button.swal2-styled {
  padding: 5px 12px !important;
  font-size: 12px;
  min-width: 62px;
}
.common_fxrate_sec {
  .fx-rate-grid {
    height: calc(100vh - 62px);
  }
  .ag-paging-panel {
    border-top: 0;
    font-size: var(--font-size-11);
    color: #000;
    .custom-page-btn {
      line-height: 1 !important;
    }
  }
}

.checkbank-common-grid {
  .container-grid {
    width: 430px;
    margin-right: 0px !important;
    height: calc(100vh - 69px);
    .ag-header-cell.ag-floating-filter {
      padding-top: 2.7px !important;
      .ag-text-field-input {
        margin-top: -2px !important;
      }
    }
  }
}


.sideBarClass{
.ag-side-bar {
  .ag-column-select-column-label {
      font-size: var(--font-size-11) !important;
      font-weight: 400 !important;
      color: #000;
  }
  .ag-side-button-label{
    font-weight: 700;
    font-size: var(--font-size-11);
    color: #3e5569;
}
.ag-input-wrapper {
  line-height: normal;
}
.ag-side-buttons {
   background: #fff;
  .ag-side-button {
    background: #fff;
    border-bottom: 0;
  }
}
}
}

.masterdetails{
  .ag-details-grid {
    max-width: 350px;
    .ag-header.ag-focus-managed{
      height: 28px !important;
      min-height: 28px !important;
      .ag-header-cell-label {
        justify-content: center;
    }
    }
  }
}
.cashsub-table p {
  margin-bottom: 2px;
  text-align: right;
}

.sidebar-item {
  cursor: pointer!important;
}


.common-grids .ag-checkbox-input-wrapper::after {
  content: none;
}

.custom_modal{
  max-width: 470px;
  .modal-body {
    max-height: calc(100vh - 106px);
    overflow: auto;
   }
}

.retail-card {
  margin: 10px;
  margin-top: 20px;
  border: 1px solid #c4c4c4;
  color: #000;
  margin-left: 16px;
  .card-header {
    padding: 5px 10px 10px 5px;
    font-weight: 600;
    color: #000;
    font-size: 16px;
    background: #e1e1e1;
  }
  .card-title{
    font-size: 40px;
    font-weight: bold;
  }
}
.view_credebt_list {
  padding: 0;
  margin: 0;
  list-style: none;
  li{
  a {
    &:last-child{
      border-bottom: none;  
    } 
    display: block;
    font-size: 14px;
    padding: 10px;
    color: #394f6c;
    border-bottom: 1px solid #c4c4c475;
  }
}
}
.user-menu-sidebar{ 
button.dropdown-toggle {
  padding: 8px 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  img {
    max-width: 20px;
    margin-right: 8px;
    height: 16px;
  }
  span {
    display: block;
    width: 87px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    left: -3px;
  }
}


.dropdown-menu {
  &:active{
    background:#394f6c;
    border: 1px solid #394f6c;
  }
  border: 1px solid #394f6c;
  padding: 0;
  min-width: 130px;
  border-radius: 4px !important;
  .dropdown-item {
    padding: 8px 10px;
  }
}
}
.sidebar-nav ul li.sidebar-item.active {
  background: #394f6c;
}
#main-wrapper{ 
.left-sidebar{
  overflow: auto !important;
  overflow-x: hidden !important;
  height: 100vh;
}
.menu-image {
  margin-right: 5px;
  margin-left: 6px;
  max-width: 18px;
}
.user-menu-sidebar {
  justify-content: flex-start;
  margin-left: 10px;
}
}
#main-wrapper[data-layout=vertical][data-sidebartype=mini-sidebar] .left-sidebar:hover .user-menu-sidebar {
  justify-content: flex-start;
}
.topbar {
  height: 0;
}
.sidebar-nav {
  height: calc(100vh - 120px);
  overflow: hidden;
  overflow-y: auto;
}
.check_icon {
  max-width: 25px;
  margin-right: 5px;
}
.retail-investor-card{
  margin: 10px;
  margin-top: 5px;
  .card-header {
    padding: 5px 10px 10px 5px;
    font-weight: 600;
    color: #000;
    font-size: 16px;
} 
  .card-body{ 
    color: #202d3d;
    max-width: 265px;
    background: #e1e1e1;
    margin-bottom: 15px;
    border-radius: 4px;
    display: flex;
.card-title {
  font-size: 32px;
  font-weight: bold;
}
}
}
.dashboard-card-title  {
  padding: 0px 0 0px 5px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}
.insvestor_grid_main {
  display: flex;
  flex-wrap: wrap;
  .insvestor_content {
    width: calc(100% - 49px);
}
}
.insvestor_grid {
  color: #202d3d;
  width: 260px;
  margin:0 8px;
  background: #e1e1e1;
  margin-bottom: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  margin-left: 6px;
  @media screen and (max-width:991px) {
    max-width: 100%;
  }
.insvestor_icon{ 
  background: #202d3d;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  }
  .card-title {
    font-size: 32px;
    font-weight: bold;
    @media screen and (max-width:1199px){
      font-size: 26px;
    }
  }
  }
  .dashboard-page .page-wrapper{
    overflow: auto;
    height: 100vh;
  }

.common_card{
  margin: 10px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  padding: 20px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 5px;
  .card-header{
    .card-title {
      padding: 5px 0 0px 5px;
      font-size: 20px;
      font-weight: 600;
      color: #000;
      @media screen and (max-width:767px){
        font-size: 18px;
      }
  }
}
  .card-body{ 
    @media screen and (max-width:767px){
        padding-bottom: 0;
    }
  }
}

.common-btn{
  background: #394f6c;
  color: #fff;
  border: 1px solid #394f6c;
  &:hover,&:active,&:focus{
    background: #202d3d !important;
    color: #fff !important;
    border: 1px solid #202d3d !important;
  } 
  &:disabled {
    background: #bfbfbf;
    color: #fff;
  }
  .btn-icon {
    max-width: 16px;
    margin-right: 2px;
  }
}
.common-form-sec{  
label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.placeholder-text {
  color: #202d3d;
  font-size: 12px;
}
.form-control,.form-select{
 
  // &:focus{
  //   box-shadow: none;
  //   outline: none;
  //   border:1px solid #394f6c;
  // }
}
}

.custom-scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
  margin:3px 0;
}

.custom-scrollbar::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #394f6c;
  margin:2px 0;
}
// #main-wrapper{
//   overflow:auto;
// }
.mat-mdc-tab-body-content {
  overflow: hidden;
}
.row{
  margin: 0;
  padding: 0;
}
mat-tab-group {
  position: relative;
  // z-index: 99999 !important;
  @media screen and (max-width:991px) {
    z-index: 50 !important;
  }
}
.ng-select.custom{
  z-index: 10 !important;
}

@media screen and (max-width:991px){
  .right_hamburger{
    opacity: 0;
  }
  #main-wrapper[data-layout=vertical][data-sidebartype=full] .page-wrapper {
    margin-top: 51px;
    height: calc(100vh - 51px) !important;;
  }
  .bank-detail-page {
    #main-wrapper  {
      .page-wrapper {
        overflow: hidden !important;
      }
    }
  }
  .header-title .card-title {
    font-size: 16px;
  }
  .topbar{ 
    .navbar-header {
      border-bottom: 1px solid #ddddde;
  }
  }
}

@media screen and (max-width:767px){
#main-wrapper .page-wrapper {
  margin-top: 45px;
  .navbar-brand .dark-logo {
    max-width: 170px;
  }
}
.retail-card{ 
  .card-header {
    padding: 5px 7px 7px 5px;
  }
  .card-body {
    padding: 10px;
  }
  .card-title {
    font-size: 30px;
  }
}
.view_credebt_list li a {
  font-size: 12px;
  padding: 5px;
}
.iframe-container{ 
.mobile_common_grid{
    width: 100% !important;
    height: calc(100vh - 130px) !important;
    .ag-root-wrapper.ag-layout-normal {
      height: calc(100vh - 132px);
  }
  .ag-paging-panel{
    border-color: #c4c4c4;
  }
}

}
.retail-investor-card{ 
  .card-header {
    padding: 5px 7px 7px 5px;
  }
 .card-body { 
  .card-title{
    font-size: 28px;
  }
}
}
}

input[type="number"] {
  text-align: right !important;
}
.numberText {
  text-align: right !important;
}


.container-grid-tab {
  height: calc(100vh - 118px);
  .container-grid{
    height: calc(100vh - 118px);
}
}
.custom_popup{ 
  justify-content: center;
  .modal-header{
   background: #202d3d;
   h5.modal-title{
    color:#fff;
    font-size: 18px;
   }
   button.close {
    background: transparent;
    color: #fff;
}
  }
  .modal-content {
    background: #efefef;
    .card{
      background-color: transparent;
      .card-header{
        background-color: transparent;
      }
    }
    .card-title {
      font-size: 16px;
  }
}
.field-group {
  padding-left: 5px !important;
  padding-right: 5px !important;
  @media screen and (max-width:767px){
    width: 50% !important;
  }
}
.additional_sec {
  border-top: 1px solid #cbd6e2;
  margin-top: 9px;
  padding-top: 9px;
}
  .cancel_btn{
    background: transparent;
    color: #202d3d;
    border: 1px solid #202d3d;
    width: 80px;
    &:hover{
      background-color: #202d3d;
      color:#fff;
    }
  }
  .save_btn{
    background-color: #202d3d;
    border: 1px solid #202d3d;
    color: #fff;
    width: 80px;
    &:disabled{
      background: #afafaf;
      border: 1px solid #afafaf;
      opacity: 1;
    }
  }
}
.custom_popup_responsive{
  .field-groups{
    @media screen and (max-width:767px){
      width: 50% !important;
    }
  }
}
.container-grid{ 
  input,
  select,
  optgroup {
    height: 18px;
  }
}
label{
  cursor: pointer;
}
.required {
  color: red;
}
.cursor-not-allowed * {
  cursor: not-allowed!important;
}


.container-grid .ag-cell,
.container-grid .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  border: 1px solid rgba(227, 227, 227, 0) !important;
  border-right: 1px solid #c4c4c4 !important;
  border-bottom: 1.1px solid #c4c4c4 !important;
}

.custom {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    padding: 0px 0px 0px 7px !important;
    font-weight: 400;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 0px 0px 0px 7px !important;
  }

  .ng-option.ng-star-inserted.ng-option-marked {
    background-color: #2196f3 !important;
    font-weight: 400 !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: 400 !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    min-width: 235px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #ddd;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #919191;
  }
}
.flatpickr-current-month input.cur-year {
  padding: 0 19px 0 0.5ch;
}

.numInputWrapper span {
  padding: 0 4px 0 1.6px;
}