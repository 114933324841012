/* You can add global styles to this file, and also import other style files */
/* Core Grid CSS */
@import "ag-grid-community/styles/ag-grid.css";
/* Quartz Theme Specific CSS */
@import "ag-grid-community/styles/ag-theme-quartz.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

.ag-header-cell {
  height: 100% !important;
}

.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  outline: 1px solid grey !important;
}

.ag-header.ag-header-allow-overflow .ag-header-row {
  overflow: hidden;
}

input {
  text-align: left !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
